<template>
  <v-row class="fill-height">
    <v-container class="fill-height justify-center my-auto" fluid>
      <v-card width="400">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-img src="@/assets/logo.png" max-width="250" contain max-height="100">
          </v-img>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-progress-linear v-show="loading" indeterminate color="primary" background-opacity="0">
        </v-progress-linear>
        <v-card-text>
          <v-form ref="invitedUserRegistrationForm" lazy-validation class="px-6">
            <v-text-field v-model="signUpForm.name" label="Full Name" :rules="rules.nameRules" disabled required>
            </v-text-field>
            <v-text-field v-model="signUpForm.email" label="E-mail" :rules="rules.emailRules" disabled required>
            </v-text-field>
            <v-text-field v-model="signUpForm.userRole" label="Role" :rules="rules.nameRules" disabled required>
            </v-text-field>
            <v-text-field v-model="signUpForm.courthouse" label="Courthouse" :rules="rules.nameRules" disabled required>
            </v-text-field>
            <v-text-field v-model="signUpForm.password" :type="passwordFieldType" label="Password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="toggleshowPassword"
              :rules="rules.passwordRule" required>
            </v-text-field>
            <v-text-field v-model="confirmPassword" :type="passwordFieldType" label="Confirm Password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="toggleshowPassword"
              :rules="rules.confirmPasswordRule" required @keyup.enter="createAccount">
            </v-text-field>
            <v-btn color="primary" dark block large @keyup.enter="createAccount" @click="createAccount">
              Create Account
            </v-btn>
          </v-form>

        </v-card-text>
      </v-card>
    </v-container>
  </v-row>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'invitedUserRegistration',
  data() {
    return {
      showPassword: false,
      passwordFieldType: 'password',
      loading: false,
      signUpForm: {
        name: '',
        email: '',
        password: '',
        userRole: '',
        courthouse: '',
        invitationId: '',
      },
      confirmPassword: '',
      rules: {
        nameRules: [
          v => !!v || 'Full name is required',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        passwordRule: [
          v => !!v || 'Password is required',
          v => (v && v.length >= 8) || 'Password must be 8 characters long',
        ],
        confirmPasswordRule: [
          v => !!v || 'Password is required',
          v => (v && v.length >= 8) || 'Password must be 8 characters long',
          v => (v && v === this.signUpForm.password) || 'Password must match',
        ]
      }
    }
  },
  methods: {
    createAccount() {
      if (this.$refs.invitedUserRegistrationForm.validate()) {
        this.loading = true;
        this.$store.dispatch('createAccount', this.signUpForm)
          .then((response) => {
            this.loading = false;
            this.showPassword = false;
            this.$store.dispatch('showSnackBar', {
              text: response,
              color: 'success',
            })
            this.$store.commit("setConfirmationDialog", true)
          },
            error => {
              this.loading = false;
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error',
              })
            })
      }
    },
    toggleshowPassword() {
      this.showPassword = !this.showPassword;
      this.passwordFieldType = (this.showPassword) ? 'text' : 'password';
    },
  },
  computed: {
    ...mapGetters(['invitedUserProfile'])

  },
  mounted() {
    this.loading = true;
    const uId = this.$route.params.id;
    console.log(`Parameters: ${this.$route.params.id}`);
    this.$store.dispatch('getInvitedUserInformation', uId)
      .then(response => {
        console.log('Response: ', response)
        this.loading = false;
        this.signUpForm.name = this.invitedUserProfile.name;
        this.signUpForm.email = this.invitedUserProfile.email;
        this.signUpForm.userRole = this.invitedUserProfile.userRole;
        this.signUpForm.courthouse = this.invitedUserProfile.courthouse;
        this.signUpForm.invitationId = uId;
      }, error => {
        this.$store.dispatch('showSnackBar', {
          text: error,
          color: 'error',
          timeout: 0,
        })
        // this.$router.push('/');
      })

  }
}

</script>